@import url('https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.service_page {
    font-family: "Times New Roman";
    padding: 5rem 3rem 0 3rem;
    max-width: 1200px;
    margin: 0 auto;
}

.section_container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.696);
    padding: 2rem;
    margin-bottom: 2rem;
}

.background {
    background-color: rgb(243, 243, 243);
    padding-bottom: 4rem;
}

.title {
    font-weight: bold;
    text-decoration: none;
    color: #2c3e50;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 675px;
    gap: 0.5rem;
}

.scaler {
    font-size: 24px
}

.greeting {
    font-style: italic;
    margin-top: none;
}

.explanation {
    font-weight: bolder;
}

.high_speeds {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-radius: 6px;
    margin: 1.5rem 0;
    background-color: #d2d2d2;
}

.hs_price {
    font-weight: bold;
    color: var(--color-primary-400);
}

p {
    text-indent: 25px
}

.other {
    margin-left: 0;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    padding: 1.5rem;
}

.other_header {
    font-style: italic;
    margin-bottom: 1.2rem;
    color: #424242;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 0.5rem;
}

.other_container {
    display: flex;
    flex-direction: inline;
    justify-content: space-between;
}

.other_section {
    width: 45%;
}

.o__s {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
    padding: 0.5rem;
    transition: background-color 0.2s;
}

.o__s:hover {
    background-color: #f5f5f5;
    border-radius: 4px;
}

.footer {
    text-align: center;
    margin-top: 2rem;
    padding: 2rem;
    background-color: #2c3e50;
    color: white;
    border-radius: 8px;
}

.footer_content {
    font-weight: bold;
}

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  .bm-burger-bars {
    background: #373a47;
  }
  
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  .bm-cross {
    background: #bdc3c7;
  }

@media(min-width: 1855px) {
    .service_page {
        max-width: 1855px;
        margin: 0 auto;
    }
}

@media(max-width: 949px) {
    .service_page {
        width: 100%;
    }
}

@media (max-width: 885px) {

    .other_container {
        flex-direction: column;
    }

    .other_section {
        width: 100%;
        margin-bottom: 2rem;
    }
}

@media (max-width: 1162px) {
    .high_speeds {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }
}