@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

* {
  box-sizing: border-box;
}

:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --color-gray-100: #f4f3f1;
  --color-gray-200: #dddbd8;
  --color-gray-300: #ccc9c6;
  --color-gray-400: #aeaba7;
  --color-gray-500: #8a8784;
  --color-gray-600: #656360;
  --color-gray-700: #4b4a47;
  --color-gray-800: #31302e;
  --color-gray-900: #1f1d1b;

  --color-primary-100: #fcf3e1;
  --color-primary-200: #fceccd;
  --color-primary-300: #0cc0df;
  --color-primary-400: rgb(65, 165, 247);
  --color-primary-500: rgb(36, 139, 224); 
  --color-primary-600: #f9c762;
  --color-primary-700: #fbc14d;
  --color-primary-800: #fab833;
  --color-primary-900: rgb(52, 154, 172);
  --bubble: rgba(255, 255, 255, 0.692);
}

body {
  margin: 0;
}

.classNameTray {
  width: 50%;
}

.bg-body-tertiary {
  background-color: white;
}