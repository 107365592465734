.contact {
    margin-top: 1.5rem;
    padding: 3rem 0 4rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.contact section {
    width: 50%;
}

.contact_info {
    text-align: center;
    margin-top: -4rem;
}

.contact_info h1 {
    color: var(--color-primary-400);
}

.contact_info p {
    font-size: 1.5rem; 
    text-align: center;
    max-width: 22rem;
    margin: 0 auto;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
}

.form input,
.form textarea {
    width: 80%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 50px; 
    transition: border 0.3s ease, box-shadow 0.3s ease;
}

.form input:focus,
.form textarea:focus {
    outline: none;
    border: 1px solid var(--color-primary-400); /* Light blue border on focus */
    box-shadow: 0 0 8px rgba(26, 115, 232, 0.5);
}

.form textarea {
    resize: none; /* Prevent resizing */
    height: 150px;
    border-radius: 20px;
}

.form button {
    width: 50%;
    background-color: var(--color-primary-400);
    color: white;
    border-radius: 50px;
    border: none;
    padding: 5px; 
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.form button:hover {
    background-color: var(--color-primary-500);
    transform: scale(1.05);
}

@media screen and (max-width: 754px) {
    .contact {
        display: flex;
        flex-direction: column;
    }
    
    .contact_info {
        text-align: center;
        margin-top: 0px;
    }

    .form input,
    .form textarea {
        max-width: 25rem;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 50px; 
        transition: border 0.3s ease, box-shadow 0.3s ease;
    }

    .form textarea {
        resize: none;
        height: 150px;
        border-radius: 20px;
    }

    .contact section {
        width: 90%;
    }
}