.box {
    width: 320px; 
    height: 410px;
    overflow: hidden;
    margin-top: 30px;
    background: #e8e8e8;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 9px 0px #282828;
}

.contain .box .high_speeds {
    align-items: center;
    max-width: 100%;
}

.img_box img {
    width: 260px;
}

.info {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    min-height: 75px;
}

.titles {
    font-weight: bold;
}

.view_botton {
    margin-top: 30px;
    margin-left: 50.35px;
    padding: 10px 30px;
    border: 1px solid var(--color-primary-400);
    outline: none;
    background-color: var(--color-primary-400);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.view_botton:hover {
    border: 1px solid var(--color-primary-300);
    background-color: white; 
    color: var(--color-primary-400)
}

.view_disabled {
    margin-top: 30px;
    margin-left: 50.35px;
    padding: 10px 30px;
    border: 1px solid var(--color-primary-300);
    outline: none;
    background-color: gray;
    color: #fff;
    border-radius: 5px;
    cursor: not-allowed;
    transition: all 0.3s;
}

.prices {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    font-weight: bold;
}

.old_price {
    text-decoration: line-through;
    font-weight: normal;
}

.botton_edge_case {
    margin-left: 50.35px;
    padding: 10px 30px;
    border: 1px solid var(--color-primary-400);
    outline: none;
    background-color: var(--color-primary-400);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.botton_edge_case:hover {
    border: 1px solid var(--color-primary-300);
    background-color: white; 
    color: var(--color-primary-400)
}

.edge_case_title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 18px;
}