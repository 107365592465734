@import url("https://fonts.googleapis.com/css2?family=Special+Elite&display=swap");

.all_quotes {
    background-image: url(../../static/sunset-boat-stretch.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 6rem 3rem;
    font-size: 1.5rem;
}

.block_quote {
    padding: 1rem 0.5rem 1rem 0.5rem;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 25px;
    transition: all 0.5s ease-in-out;
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 1500px;
    margin: 0rem auto 6rem auto;
}

.block_quote:hover {
    background-color: rgba(255, 255, 255);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}

.source {
    margin-left: 44.5%;
}

.quote {
    max-width: 1110px;
    margin: 0 auto;
}

.source_profile {
    border-radius: 50%;
    width: 140px;
    z-index: 2;
}