.buttons {
    /* margin-top: 10px; */
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.order_button {
    background-color: var(--color-primary-300);
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid var(--color-primary-300);
    color: #fff;
    cursor: pointer;
    transition: all 0.3s;
    width: 160px;
}

.order_button:hover {
    border: 1px solid var(--color-primary-300);
    background-color: #fff;
    color: var(--color-primary-300);
}

.form input{
    width: 100%;
    height: 40px;
    padding: 2px 4px 2px 12px;
    border-radius: 25px;
    font-size: 1.25rem
}

.form input:focus {
    border: none;
}

.form_field {
    border: 2px solid rgb(192, 192, 192);
}

.message_class {
    width: 100%;
    height: 12.5rem;
    border: 2px solid rgb(192, 192, 192);
    border-radius: 25px;
    padding: 7px 12px;
    font-size: 1.25rem;
}

.message_class::placeholder {
    font-size: 1.25rem;
    
}

.cel_section {
    display: flex;
}

.countries {
    width: 100%;
    height: 40px;
    padding: 2px 4px 2px 12px;
    border-radius: 25px;
    border: 2px solid rgb(192, 192, 192);
    margin-bottom: 20px;
}

.cel_section input {
    width: 80%;
    border-radius: 0 25px 25px 0;
}

.country_code {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center ;
    border-radius: 25px 0 0 25px;
    border: 2px solid rgb(192, 192, 192);
    border-right: none;
}

.c_code {
    position: relative;
    right: 2rem;
}

.error_message {
    color: red;
    font-size: 0.75rem;
    height: 20px;
    text-align: center;
}

.input_error {
    border: 2px solid rgb(243, 19, 19);
}


/* I will succeed */