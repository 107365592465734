.backdrop {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
}

.overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    overflow-y: auto;
}

.content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
/* @media (min-width: 1430px) {
    .overlay {
        left: 10%;
        width: 80%;
        max-width: 200rem;
    }
} */

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-3rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

:global(.no_scroll) {
    overflow: hidden;
}