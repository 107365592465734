.header {
    height: 6rem;
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.logo {
    height: 75%;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.3rem;
}

.links a {
    margin: 0rem 1rem;
    text-decoration: none;
    color: black;
}

.links a:hover {
    margin: 0rem 1rem;
    text-decoration: underline;
    color: var(--color-primary-400);
}

.links a.active {
    color: var(--color-primary-400);
}

.ham_menu {
    display: none;
}

@media screen and (max-width: 850px) {
    .header {
        min-height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logo {
        margin-top: 1rem;
        height: 50%;
    }
    
    .links a {
        margin: 1rem 0.3rem;
    }

    .links a:hover {
        margin: 1rem 0.3rem;
    }
} 


