.ls_title {
    text-align: center;
    margin: 4rem auto auto auto;
    padding-top: 1rem;
    color: var(--color-primary-400);
    border-top: 1px solid rgb(182, 182, 182);
    width: 75%;
}

.low_speeds {
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 10px 30px;
    margin: 0 auto;
    row-gap: 20px;
    column-gap: 53px;
    margin: 0 auto;
}

@media (max-width: 1498px) {
    .low_speeds {
        max-width: 1126px;
    }
}

@media (max-width: 1125px) {
    .low_speeds {
        max-width: 753px;
    }
}

@media (max-width: 752px) {
    .low_speeds {
        max-width: 378px;
    }
}
