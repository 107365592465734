.container {
    position: relative;
    height: 178px;
}

.unordered_lst {
    padding-left: 20px;
}

.ul_section {
    height: 100%;
    overflow-y: auto;
    position: relative;
    border-bottom: 2px solid #e0e0e0;
    transition: border-bottom 0.3s ease;
}

.ul_section.at_bottom {
    border-bottom: none;
}

/* .ul_section:before {
    position: absolute;
    content: '';
    z-index: 30;
    width: 356.001px;
    height: 100px;
    margin-top: 78px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    pointer-events: none;
} */