.general_info {
    display: flex;
    margin-bottom: 1.75rem;
}

.profile_img {
    flex: 1;
    max-width: 25%;
    height: 100%;
    margin: auto;
    object-fit: contain;
    margin-left: 15px;
    border-radius: 5px;
}

.text_section {
    flex: 3;
    padding: 0 15px;
}

@media screen and (max-width: 820px) {
    .general_info {
        display: flex;
        flex-direction: column;
    }

    .text_section {
        width: 80%;
        margin: 0px auto;
    }
    
    .profile_img {
        max-width: 15rem;
        margin: 0px auto;
    }
}
