@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.title {
    animation: slideUp 0.8s ease-out forwards;
}

.pledge {
    opacity: 0;
    animation: slideUp 0.8s ease-out forwards;
    animation-delay: 0.4s;
}

.content {
    opacity: 0;
    animation: slideUp 0.8s ease-out forwards;
    animation-delay: 0.8s;
}

.warranty_page {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    min-height: 85vh;
    color: #333;
}

.title {
    font-size: 4rem;
    color: var(--color-primary-400);
    margin-bottom: 1rem;
}

.pledge {
    font-size: 1.75rem;
    color: #333;
    margin-bottom: 1.25rem;
}

.pledge span {
    color: var(--color-primary-400);
    font-weight: bold;
}

.content {
    font-size: 1.125rem;
    line-height: 1.6;
    text-align: center;
    max-width: 800px;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}