.for_sale_title{
    text-align: center;
    width: 75%;
    margin: 5rem auto 0rem auto; 
    padding-top: 1rem;
}

.hs_title {
    text-align: center;
    margin-bottom: 1rem;
    color: var(--color-primary-400);
}

.high_speeds {
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 10px 30px;
    margin: 0 auto;
    row-gap: 20px;
    column-gap: 53px;
}

@media (max-width: 1520px) {
    .high_speeds {
        max-width: 1126px;
    }
}

@media (max-width: 1125px) {
    .high_speeds {
        max-width: 753px;
    }
}

@media (max-width: 752px) {
    .high_speeds {
        max-width: 378px;
    }
}