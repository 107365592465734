.img_cache {
    width: 100%;
    max-width: 500px;
    flex: 1 1 300px;
}

.active_img {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
    margin-bottom: 10px;
    transition: all 0.3s;
}

.contain_loader {
    position: absolute;
    top: 0em;
    right: 16rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-Items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
}

.loading {
    width: 30px;
    height: 30px;
    border: 3px solid #ccc;
    border-top: 3px solid #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.expanded_img {
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 40;
    width: 100%;
    border-radius: 15px; 
}

.inactive_imgs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.side_img {
    width: 84px;
    height: 56px;
    max-width: 100%; 
    margin-right: 10px; 
    margin-bottom: 10px;
}

.selected_img {
    width: 84px;
    height: 56px;
    max-width: 100%; 
    margin-right: 10px; 
    margin-bottom: 10px;
    box-shadow: 0px 0px 1px 2px var(--color-primary-300);
}

.details {
    width: 100%;
    max-width: 500px;
    flex: 1 1 300px;
    padding: 0px 10px;
}

.details_list {
    margin-bottom: 8px;
}

.top {
    display: flex;
    justify-content: space-between;
}

.product_group {
    margin-bottom: 8px;
    color: var(--color-primary-400);
}

.close {
    color: var(--color-primary-400);
    height: 18px;
    width: 18px;
    border-radius: 50%;
    transition: all 0.3s;
}

.close:hover {
    box-shadow: 0px 0px 0px 6px #E8EDE8;
    color: red;
    cursor: pointer;
}

.product_name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px
}

.price {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 11px;
}

.dets_header {
    border-bottom: 1px rgb(224, 224, 224) solid;
    margin-bottom: 20px
}

.list_section {
    height: 210px;
}

.details_title {
    font-size: 20px;
    margin-left: 15px;
    margin-bottom: 10px;
}

.buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.buttons > button {
    width: 160px;
}

.order_button {
    background-color: var(--color-primary-400);
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid var(--color-primary-400);
    color: #fff;
    cursor: pointer;
    transition: all 0.3s;
}

.order_button:hover {
    border: 1px solid var(--color-primary-400);
    background-color: #fff;
    color: var(--color-primary-400);
}

.prompt_message {
    margin-bottom: 0.8rem;
}

@media (max-width: 768px) {
    .inactive_imgs {
        justify-content: center;
    }

    .side_img,
    .selected_img {
        width: 70px;
        height: 47px;
    }

    .price {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .buttons > button {
        width: 130px;
    }

    .order_button {
        padding: 8px 15px;
    }
}

