.carousel_item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    background-size: cover;
    background-repeat:  no-repeat;
    background-position: center center;
    aspect-ratio: 1024/365;
    box-shadow: 0 5px 15px black;
}

.first_slide {
    background-image: url('../../static/carousel-img-1.jpeg');
}

.second_slide {
    background-image: url('../../static/carousel-img-2.jpeg');
}

.third_slide {
    background-image: url('../../static/carousel-img-3.jpeg');
}

.fourth_slide {
    background-image: url('../../static/carousel-img-4.jpeg');
}

.fifth_slide {
    background-image: url('../../static/carousel-img-5.jpeg');
}

.first_slide p {
    position: relative;
    bottom: 6rem;
}

.second_slide p {
    position: relative;
    bottom: 5rem;
}

.third_slide p {
    font-size: 2vw;
    position: relative;
    bottom: 2rem;
    left: 22rem;
    width: 400px;
}

.fourth_slide p {
    position: relative;
    bottom: 5rem;
}

.fifth_slide p {
    position: relative;
    left: 10rem;
    bottom: 3rem;
    max-width: 60%;
    color: black
}

.carousel_item_text {
    font-size: 3rem;
    height: 1.5rem;
    max-width: 40rem;
    color: white;
    white-space: normal;
    line-height: normal;
}

